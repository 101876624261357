<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined>
        <v-card-title>
          <v-row class="d-flex justify-space-between">
            <v-col cols="12">Quarterly Plan Template</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- Start Quarterly Plan section -->
          <v-container>
            <div v-if="planTemplate">
              <v-row>
                <v-col class="font-weight-black text-h6" md="6" cols="12">
                  Quarter: Q{{ planTemplate.quarter }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-black text-h6" md="6" cols="12">
                  Name: {{ planTemplate.title }}
                </v-col>
                <v-col md="6" cols="12">
                  <router-link
                    v-if="
                      permissions.quarterlyPlanTemplate &&
                        permissions.quarterlyPlanTemplate.canEdit &&
                        planTemplate.isActive
                    "
                    class="mx-1"
                    :to="
                      `/customers/${customerId}/planTemplates/${planTemplate.id}/edit`
                    "
                  >
                    <v-btn class="orange-bg white--text">Edit</v-btn>
                  </router-link>
                  <v-btn
                    class="mx-1"
                    v-if="
                      permissions.quarterlyPlanTemplate &&
                        permissions.quarterlyPlanTemplate.canCreate
                    "
                    @click="showDeletePlan = true"
                  >
                    Delete
                  </v-btn>
                  <router-link
                    class="mx-1"
                    :to="
                      `/customers/${customerId}/plans/new?sourceTemplateId=${planTemplateId}`
                    "
                  >
                    <v-btn class="orange-bg white--text">
                      Create New Plan From Template
                    </v-btn>
                  </router-link>
                </v-col>
                <!-- <v-col> </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <hr />
                  <h6>{{ month1Name }} Tasks</h6>
                  <div>
                    <table width="100%" class="mt-2">
                      <tr
                        v-for="(item, i) in planTemplate.day30Tasks.$values"
                        :key="'tr30' + i"
                      >
                        <td width="80%">
                          {{ item.description }}
                        </td>
                        <td>
                          {{ getStatusName(item.status) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h6>{{ month1Name }} Notes</h6>
                  <div>
                    <v-textarea
                      readonly
                      v-model="planTemplate.day30"
                      solo
                    ></v-textarea>
                  </div>
                  <hr />
                  <h6>{{ month2Name }} Tasks</h6>
                  <div>
                    <table width="100%" class="mt-2">
                      <tr
                        v-for="(item, i) in planTemplate.day60Tasks.$values"
                        :key="'tr60' + i"
                      >
                        <td width="80%">
                          {{ item.description }}
                        </td>
                        <td>
                          {{ getStatusName(item.status) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h6>{{ month2Name }} Notes</h6>
                  <div>
                    <v-textarea
                      readonly
                      v-model="planTemplate.day60"
                      solo
                    ></v-textarea>
                  </div>
                  <hr />
                  <h6>{{ month3Name }} Tasks</h6>
                  <div>
                    <table width="100%" class="mt-2">
                      <tr
                        v-for="(item, i) in planTemplate.day90Tasks.$values"
                        :key="'tr90' + i"
                      >
                        <td width="80%">
                          {{ item.description }}
                        </td>
                        <td>
                          {{ getStatusName(item.status) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h6>{{ month3Name }} Notes</h6>
                  <div>
                    <v-textarea
                      readonly
                      v-model="planTemplate.day90"
                      solo
                    ></v-textarea>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-dialog v-model="showDeletePlan" persistent max-width="290">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  Are you sure you want to delete the template?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!statusProcessing"
                    color="green darken-1"
                    text
                    @click="showDeletePlan = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deletePlanTemplate()"
                    :loading="statusProcessing"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
          <!-- End Quarterly Plan section -->
        </v-card-text></v-card
      >

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  DELETE,
  GET_QUERY,
  API_CUSTOMERS,
  API_QUARTERLY_PLAN_TEMPLATES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    planTemplate: {
      quarter: 1,
      day30: "",
      day60: "",
      day90: ""
    },
    pageText: "",
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    permissions: {},
    baseUrl: `${process.env.VUE_APP_DASHBOARD_URL}`,
    month1Name: "30 Days",
    month2Name: "60 Days",
    month3Name: "90 Days",
    showDeletePlan: false,
    statusProcessing: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    planTemplateId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
  },
  watch: {},
  async mounted() {},
  async created() {
    await this.getComunityInfo();
    if (this.planTemplateId) await this.getQuarterlyPlanTemplate();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    async deletePlanTemplate() {
      this.statusProcessing = true;

      let self = this;
      let url = `${API_QUARTERLY_PLAN_TEMPLATES}`;
      self.$store
        .dispatch(DELETE, {
          listName: url,
          id: self.planTemplateId
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Quarterly Plan Template has been deleted";
            self.showDeletePlan = false;

            self.$snackbar.showMessage({
              content: self.snackbarContent,
              color: self.snackbarColor
            });

            self.$router.push({
              name: "CustomerQuarterlyPlanTemplates",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }

          self.showDeletePlan = false;
          self.statusProcessing = false;

          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    },
    getStatusName(status) {
      if (status === 0) return "Open";
      if (status === 1) return "In Process";
      if (status === 2) return "Completed";
    },

    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },

    async getQuarterlyPlanTemplate() {
      let url = `${API_QUARTERLY_PLAN_TEMPLATES}/${this.planTemplateId}`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          this.planTemplate = {
            quarter: 1,
            day30: "",
            day60: "",
            day90: ""
          };
          if (response.status >= 200 || response.status <= 204) {
            this.planTemplate = response.data;
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
            if (this.planTemplate) {
              if (this.planTemplate.quarter == 1) {
                this.month1Name = "January";
                this.month2Name = "February";
                this.month3Name = "March";
              } else if (this.planTemplate.quarter == 2) {
                this.month1Name = "April";
                this.month2Name = "May";
                this.month3Name = "June";
              } else if (this.planTemplate.quarter == 3) {
                this.month1Name = "July";
                this.month2Name = "August";
                this.month3Name = "September";
              } else if (this.planTemplate.quarter == 4) {
                this.month1Name = "October";
                this.month2Name = "November";
                this.month3Name = "December";
              }

              this.planTemplate.day30Tasks.$values.forEach(element => {
                if (element.status === 0) element.statusValue = "0";
                if (element.status === 1) element.statusValue = "1";
                if (element.status === 2) element.statusValue = "2";
              });

              this.totalDay30Tasks = this.planTemplate.day30Tasks.$values.length;

              this.planTemplate.day60Tasks.$values.forEach(element => {
                if (element.status === 0) element.statusValue = "0";
                if (element.status === 1) element.statusValue = "1";
                if (element.status === 2) element.statusValue = "2";
              });

              this.totalDay60Tasks = this.planTemplate.day60Tasks.$values.length;

              this.planTemplate.day90Tasks.$values.forEach(element => {
                if (element.status === 0) element.statusValue = "0";
                if (element.status === 1) element.statusValue = "1";
                if (element.status === 2) element.statusValue = "2";
              });

              this.totalDay90Tasks = this.planTemplate.day90Tasks.$values.length;
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        {
          title: "Quarterly Plan Templates",
          route: "/customers/" + this.customerId + "/planTemplates"
        },
        { title: this.planTemplate.title }
      ];
    },

    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        {
          title: "Quarterly Plan Templates",
          route: "/customers/" + this.customerId + "/planTemplates"
        },
        { title: this.planTemplate.title }
      ]);
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {}
};
</script>
